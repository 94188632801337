$gutter: $pad;

.flex {
    display: flex;

    // .flex-wrap
    &-wrap {
        @extend .flex;
        flex-wrap: wrap;
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    padding-left: $gutter;
    padding-right: $gutter;
    width: 100%;

    @media ($breakpoint-mobile-down) {
        padding-left: $gutter/2;
        padding-right: $gutter/2;
    }

    // .container--full-width
    &--full-width {
        @extend .container;
        max-width: none;
    }

    // .container--gutterless
    // &--gutterless {
    //     @extend .container;
    //     padding: 0;
    // }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;
    margin-right: -$gutter;
    
    .row {
        margin-left: -$gutter/2;
        margin-right: -$gutter/2;
        margin-top: -$pad !important;
        min-height: 100%;
    }
    
    @media ($breakpoint-mobile-down) {
        margin-left: -$gutter /2;
        margin-right: -$gutter /2;
    }

    // .row-wrap
    &-no-wrap {
        @extend .row;
        flex-wrap: nowrap;
    }
}

.col {
    margin-top: $pad;
    background-size: cover;
    background-position: center;
    position: relative;
}

*[class*="col-"] {
    padding-left: $gutter;
    padding-right: $gutter;

    @media ($breakpoint-mobile-down) {
        padding-left: $gutter/2;
        padding-right: $gutter/2;
    }
    
    *[class*="col-"] { 
        padding-left: $gutter/2;
        padding-right: $gutter/2;
    }

}

@mixin generateCols($device: "") {
    @for $i from 1 through 12 {
        .col#{$device}-#{$i} {
            width: ($i/12)*100%;
        }
    }
}
@mixin generateColOrders($device: "") {
    @for $i from 1 through 12 {
        .col-order#{$device}-#{$i} {
            order: $i;
        }
    }
}

@include generateCols();
@include generateColOrders();

@media ($breakpoint-mobile-up) and ($breakpoint-tablet-down) {
    @include generateCols("-tablet");
    @include generateColOrders("-tablet");
    
    .col-tablet-0 {
        display: none;
    }
}

@media ($breakpoint-mobile-down) {
    @include generateCols("-mobile");
    @include generateColOrders("-mobile");

    .col-mobile-0 {
        display: none;
    }
}

.align{
    @media ($breakpoint-tablet-up) {
        &-left {
            margin-right: auto;
        }
    
        &-center {
            margin-left: auto;
            margin-right: auto;
        }
    
        &-right {
            margin-left: auto;
        }
    }
    
    @media ($breakpoint-mobile-up) and ($breakpoint-tablet-down) {
        &-tablet-left {
            margin-right: auto;
        }
    
        &-tablet-center {
            margin-left: auto;
            margin-right: auto;
        }
    
        &-tablet-right {
            margin-left: auto;
        }
    }

    @media ($breakpoint-mobile-down) {
        &-mobile-left {
            margin-right: auto;
        }
    
        &-mobile-center {
            margin-left: auto;
            margin-right: auto;
        }
    
        &-mobile-right {
            margin-left: auto;
        }
    }
}



@mixin textAlign($device: "") {
    $alignments: left, center, right, justify;

    @each $alignment in $alignments {
        .text#{$device}-#{$alignment} {
            text-align:$alignment;
        }
    }
}

@include textAlign();

@media ($breakpoint-mobile-up) and ($breakpoint-tablet-down) {
    @include textAlign("-tablet");
}

@media ($breakpoint-mobile-down) {
    @include textAlign("-mobile");
}


.col--bg {
    background-size: cover;
    background-position: center;
    // min-height: $pad*20;

    @media ($breakpoint-mobile-down) {
        // min-height: $pad*10;
    }
}

.heading {
    @media ($breakpoint-mobile-down) {
        text-align: center;
    }

    h1, h2, h3, h4, h5, h6 {
        width: 100%;
    }
}