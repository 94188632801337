.item {
    margin-top: $pad;
    position: relative;
    
    &-icon {
        margin-right: $pad / 2;
        flex-shrink: 0;
    }

    // .item__heading
    &__heading {
        @extend .flex-align-center;
    }

    &__description {
        margin-top: $pad/3;
        font-size: 1.2rem;
    }

    // .item--stacked
    &--stacked {
        .item-icon {
            font-size: 4rem;
        }

        .item-heading {
            @extend .color-text;
        }

        .item__description {
            font-size: 1rem;
            margin-top: $pad/2;
        }
    }

    // For stages - scuffy solution
    &__arrow-down {
        position: absolute;
        bottom: -28px;
        left: 20px;
    }
}


.mini-items {
    @extend .max-500;
    @extend .flex-wrap;
}

.mini-item {
    @extend .flex-col;
    
    &__text {
        @media ($breakpoint-mobile-up) {
            margin-top: $pad/2;
        }
        @media ($breakpoint-mobile-down) {
            text-align: left;
        }
        font-weight: bold;
    }

    @media ($breakpoint-mobile-down) {
        align-items: center;
        flex-direction: row;
        padding-left: 0;
        padding-right: 0;

        &__icon {
            margin-right: $pad/3;
        }
    }
}