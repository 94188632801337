@font-face {
    font-family: 'Cabin';
    src: url('../Fonts/Cabin/Cabin-Regular.ttf');
}

@font-face {
    font-family: 'Cabin';
    src: url('../Fonts/Cabin/Cabin-SemiBold.ttf');
    font-weight: bold;
}

body {
    font-family: 'Cabin', sans-serif;
}

.font-size {
    &-1 {
        font-size: 4rem;
    }

    &-2 {
        font-size: 3rem;
    }
    
    &-3 {
        font-size: 1.5rem;
    }
    
    &-4 {
        font-size: 1.25rem;
    }
    
    &-5 {
        font-size: 1.125rem;
    }
    
    &-6 {
        font-size: 1rem;
    }
    
    &-7 {
        font-size: .875rem;
    }
    
    @media ($breakpoint-tablet-down) {
        &-1 {
            font-size: 3rem;
        }
    
        &-2 {
            font-size: 2rem;
        }
        
        &-3 {
            font-size: 1.75rem;
        }
    }
}


h1,
.h1 {
    @extend .font-size-1;
}

h2,
.h2 {
    @extend .font-size-2;
}

h3,
.h3 {
    @extend .font-size-4;
    text-transform: uppercase;
    font-weight: bold;
}

h4,
.h4 {
    @extend .font-size-4;
    text-transform: uppercase;
    // font-weight: bold;
}

h5,
.h5 {
    @extend .font-size-5;
    text-transform: uppercase;
    font-weight: bold;
}

h6,
.h6 {
    @extend .font-size-6;
    text-transform: uppercase;
    font-weight: bold;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.text-shadow {
    text-shadow: 0 0 10px rgba(color(black), .3);
}

.no-text-shadow {
    text-shadow: none;
}