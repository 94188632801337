.btn {
	@extend .h6;
	align-items: center;
	background: color(orange);
	border: none;
	color: color(white);
	cursor: pointer;
	display: inline-flex;
	font-family: 'Cabin';
	line-height: 1;
	justify-content: center;
	margin: 0;
	padding: 1rem 2rem;
	position: relative;
	text-align: center;
	text-decoration: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	transform: perspective(1px) translateZ(0);
	transition: all 0.1s;
	border-radius: 4px;
	text-transform: initial;
	font-size: 1.1rem;

	@media ($breakpoint-mobile-down) {
		display: flex;
		margin-left: auto;
		margin-right: auto;
	}

	&:active {
		transform: scale(0.95);
	}

	&--icon {
		svg,
		i {
			margin-left: $pad/4;
			transform: translateX(0);
			transition: ease-out 0.2s;
			margin-bottom: -0.13rem;
		}
	}

	&--small {
		@extend .btn;
		padding: $pad/3 $pad/2;
		font-size: 0.8rem;
	}

	&--full-width {
		width: 100%;
	}

	&--mobile-full-width {
		@media ($breakpoint-mobile-down) {
			width: 100%;
		}
	}

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: color(white);
		transform: scaleX(0);
		transform-origin: 0 50%;
		transition: transform 0.5s;
		border-radius: 4px;
	}

	&:hover {
		color: color(orange);

		&:before {
			transform: scaleX(1);
			transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
		}

		svg,
		i {
			transform: translateX(4px);
		}
	}
}
