.quote {
    padding-top: 2*$pad; 
    padding-bottom: 2*$pad; 

    // .quote__author
    &__author {
        @extend .text-right;
        @extend .h4;
        margin-top: $pad;
    }

    // .quote__content
    &__content {
        @extend .text-justify;
    }
}