.thumbnail {
    @media ($breakpoint-mobile-down) {
        &:not(:first-child) {
            margin-top: $gutter;
        }
    }

    // .thumbnail__body 
    &__body {
      // color: $text-color;
    }

    // .thumbnail__heading
    &__heading {
      @extend .h3;
      
      .thumbnail--text-center &{
        @extend .text-center;
      }
    }

    // .thumbnail__description
    &__description {
      @extend .text-justify;

      
      .thumbnail--text-center &{
        @extend .text-center;
      }
    }

    &__image {
      position: relative;
    }

    &--bg {
      .thumbnail__body {
        padding: 0 $pad $pad;
      }
    }

    &--image {
      .thumbnail__image {
        @extend .flex-center;
        background-size: cover;
        background-position: center;
        min-height: 200px;
      }

      .thumbnail__body {
        padding-top: $pad;
      }
    }


    //.thumbnail--icon
    &--icon {
      .thumbnail__image {
        @extend .flex-justify-center;
        min-height: 130px;
      }
    }

    &--hoverImage { 
      .thumbnail__hoverImage {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity $transition-duration;
        background-position: top center;
        filter: grayscale(1);
        background-position: center;
      }
      .thumbnail__image:hover {
        .thumbnail__hoverImage {
          opacity: 1;
        }
      }
    }

    // .thumbnail--horizontal 
    &--horizontal {
      @media ($breakpoint-mobile-up) {
        display: flex;
        
        .thumbnail__image {
          width: 33.33%;
        }
  
        .thumbnail__body {
          width: 66.66%;
        }
      }
    }

    &--btn-fullWidth {
      .btn {
        width: 100%;
      }
    }

    &--btn-center {
      .btn-container {
        @extend .text-center;
      }
    }
}