.img {    
    // .img-white
    &-white {
        filter: brightness(10);
    }
}

.flex {
    display: flex;

    // .flex-col
    &-col {
        @extend .flex;
        flex-direction: column;
    }

    // .flex-center
    &-center {
        @extend .flex;
        justify-content: center;
        align-items: center;
    }

    // .flex-justify-center
    &-justify-center {
        @extend .flex;
        justify-content: center;
    }

    // .flex-justify-end
    &-justify-end {
        @extend .flex;
        justify-content: flex-end;
    }

    // .flex-space-between
    &-space-between {
        @extend .flex;
        justify-content: space-between;
    }

    // .flex-align-center
    &-align-center {
        @extend .flex;
        align-items: center;
    }

    // .flex-align-stretch
    &-align-stretch {
        @extend .flex;
        align-items: stretch;
    }

    // .flex-align-flex-end
    &-align-end{
        @extend .flex;
        align-items: flex-end;
    }

    // .flex-shrink
    &-shrink {
        flex-shrink: 1;
    }

    // .flex-grow
    &-grow {
        flex-grow: 1;
    }
}

.shadow {
    box-shadow: 0 5px 5px 0 rgba(color(black), .2);
}

.img {
    // .img-300 
    &-300 {
        max-width: 300px;
        max-height: 300px;
        width: 100%;
    }
}

.max-500 {
    max-width: 500px;
    width: 100%;

    @media ($breakpoint-mobile-down) {
        margin-left: auto;
        margin-right: auto;
    }
}

.clickable {
    cursor: pointer;
    transition: transform .1s ease;
    
    &:active {
        transform: scale(.85);
    }
}

.visible{
    &-mobile {
        @media ($breakpoint-mobile-up) {
            display: none;
        }
    }
}

.hidden {
    &-mobile {
        @media ($breakpoint-mobile-down) {
            display: none;
        }
    }
}