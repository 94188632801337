

div[class*=col-] {
    > *:nth-child(n+2) {
        margin-top: $pad;
    }
}


.section {
    padding: $pad*4 0 $pad*5;
    background-size: cover;
    
    @media ($breakpoint-mobile-down) {
        padding: $pad*2 0 $pad*3;
    }

    &--invert {
        @extend .color-offwhite;
        @extend .bg-offblack;
    }
    
    // .section__subheading
    &__subheading {
        @extend .max-500;
        @extend .font-size-4;
        
        @media ($breakpoint-mobile-down) {
            text-align: justify;
        }
    }
    
    // .section__content
    &__content {

        > *:nth-child(n+2),
        &__element {
            margin-top: $pad;
        }

        .section--background & {
            @extend .section__content;
            background: rgba(color(black), .5);
            padding: $pad;
            max-width: 700px;
        }
    }

    &__row {
        padding-top: 2*$pad;
        padding-bottom: 2*$pad;
    
        .section__subheading {
            margin-top: $pad/4;
        }
    }

    // .section--bg
    &--bg {
        padding: 0;

        .col {
            @extend .flex-center;
            margin-bottom: $pad;
        }

        .section__heading {
            @extend .h3;
        }

        .col--bg {
            margin-top: 0;
            margin-bottom: 0;
            min-height: $pad*15;
        }
    }

    &--slim {
        padding: $pad*2 0 $pad*3;
    
        @media ($breakpoint-mobile-down) {
            padding: $pad*2 0 $pad*3;
        }
    }

    // .section--rows
    &--rows {
        padding-bottom: 0;
    }

    // .section--full-height
    &--full-height {
        min-height: 100vh;
    }

    // Background Images for particular sections
    &--hero {
        background-attachment: fixed;
        
        .section__heading h1{
            @extend .h1;
        }
    }

    &__row {
       .section__heading {
           @extend .h4;
       } 
    }
}
