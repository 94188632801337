.section--hero, .hero {
    background-size: cover;
    padding-top: $pad*8;
    
    @media ($breakpoint-mobile-down) {
        padding-top: $pad*4;
        .section__heading,
        .section__subheading {
            margin-left: 0;
            text-align: left;
        }
    } 
}