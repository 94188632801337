.packageCounter {
    padding: $pad $pad/2;

    .fa {
        margin-right: $pad;
    }

    &__main {
        // @extend .h2;
        font-size: 6rem; // We should have a bigger h1 or something, idk
        @extend .flex-center;
    }
}