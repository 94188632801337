.contact-form {
    form {    
        @media ($breakpoint-mobile-up) {
            max-width: 500px;
            padding: $pad;
        }

        margin-top: $pad;
    }

    .heading h2 {
        width: 100%;
    }
}