.section--logos {
    padding: $pad 0;
    position: relative;

    h1, h4 {
        @extend .bg-offwhite;
        @extend .h6;
        position: absolute;
        top: -$pad;
        padding: $pad/2 $pad;
    }
}

.logo {
    @extend .text-center;
    @include hover-shutter(color(offwhite));
    display: inline-block;
    padding: $pad;
    position: relative;

    &__caption {
        @extend .h6;
    }

    @media ($breakpoint-tablet-down) {
        padding: $pad/2;
    }
}

.slick {

    // .slick-track
    &-track {
        @extend .flex-align-center;
    }

}