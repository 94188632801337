$header-height: $pad * 3;
$header-height-sticky: $pad * 2;

.header {
	position: fixed;
	transition: all $transition-duration;
	width: 100%;
	z-index: 4;

	.header__container {
		height: $header-height;
		transition: all $transition-duration;
	}

	.header__right {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.header__logo {
		transition: all $transition-duration ease;
	}

	// .header--navOpen
	&--navOpen {
		background: color(grey);
	}

	&--sticky {
		background: rgba(color(grey), 0.9);
		border-bottom: 1px solid color(orange);

		.header__logo {
			// height: 100%;
		}

		.header__container {
			height: $header-height-sticky;
		}

		&:hover {
			background: rgba(color(grey), 0.98);
		}
	}
}
