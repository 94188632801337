form,
.form {
    input, textarea {
        display: block;
        font-size: .1rem;
        width: 100%;

        font-family: inherit;
        font: inherit;
        border: none;
        padding: $pad/2 50px;
        margin-bottom: $pad/2;
        transition: all $transition-duration;        
        
        &::placeholder {
            text-transform: uppercase;
            font-weight: bold;
            font-size: .8rem;
            opacity: .8;
        }

        &:hover{
            box-shadow: inset 2px 0 0 0 color(orange);
        }        

        &:focus {
            box-shadow: inset 4px 0 0 0 color(orange);
            outline: none;
        }
        
    }

    label {
        display: inline-block;
        font-weight: bold;
        margin-bottom: $pad/4;
    }
}

textarea {
    resize: none;
}

.input {
    position: relative;

    .fa, .fab {
        position: absolute;
        top: 18px;
        left: $pad/2;
        transition: all $transition-duration;
    }

    input:focus {
        &+ .fa,
        &+ .fab {
            color: color(orange);
            left: 19px
        }
    }
}