.contact {
    @extend .flex-align-center;

    // .contact__label
    &__label {
        @extend .flex-align-center;
        width: 100px;

        svg {
            margin-right: 6px;
        }
    }
}