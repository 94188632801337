.product {
    &:not(:last-child) {
        @media ($breakpoint-mobile-down) {
            border-bottom: 2px solid;
            padding-bottom: $pad;
        }
    }

    @media ($breakpoint-mobile-up) {
        .heading {
            opacity: .5;
            transition: all $transition-duration;
        }
    }
    
    &--active {
        .heading {
            opacity: 1;
        }
    }
}