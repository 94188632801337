.socials {
    @extend .flex-justify-end;
    margin-bottom: $pad / 2;
    position: relative;
    
    li:not(:last-child) {
        margin-right: $gutter;
    }
}

.social {
    @extend .flex-align-center;
    @extend .font-size-3;
    @extend .bg-green;
    padding: $pad / 2;
    transition: all $transition-duration;

    
    &__caption {
        width: 0;
        overflow: hidden;
        transition: all $transition-duration;
        font-size: 1.4rem;
        margin-left: 0;
    }
    
    
    &:hover {
        .social__caption {
            width: 80px;
            margin-left: $pad/2;
        }
    }   
}