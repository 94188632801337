@mixin hover-shutter($bg) {
	&:before {
		top: 50%;
		bottom: 50%;
		left: 0;
        right: 0;
        
		background: $bg;
		position: absolute;
		content: ''; 
        transition-delay: $transition-duration * 0.3;
        transition: $transition-duration;
	}
	figcaption {
		opacity: 0;
		transition-delay: 0s;
        transition: $transition-duration;

        
        left: 0;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        text-align: center;
        transform: translateY(-35%);
        width: 100%;
	}
	&:hover {
        &:before {
            top: 0;
            bottom: 0;
            
			transition-delay: 0s;
		}
		figcaption {
            opacity: 1;
            transform: translateY(-51%);
			transition-delay: $transition-duration * 0.3;
		}
	}
}
