* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    max-height: 100%;
}

ul {
    list-style-type: disc; 
    margin: $pad 0;
    padding-left: $pad; 
}

.no-list-styles {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

h1,h2,h3,h4,h5 {
    margin: 0;
}

button {
    &:focus {
        outline: none;
    }
}

*::selection {
    background: color(orange);
    color: color(white);
}