.nav {
    @extend .flex-center;
    position: fixed;
    left: 0;
    right: 0;
    // top: $header-height;
    top: 0;
    bottom: 0;
    background: color(grey);
    z-index: 1;
    color: color(white);

    transform: translateY(-100%);
    transition: transform .5s ease-in-out;
    pointer-events: none;
    
    .header--navOpen & {
        transform: translateY(0);
        pointer-events: all;
    }

    &-container {
        height: 100%;
    }
    
    // .nav__link
    &__link {
        @extend .font-size-3;
        @extend .clickable;
        display: inline-block;        
        margin-bottom: $pad;

        @media ($breakpoint-mobile-down) {
            // Can't @extend from within a media query
            font-size: 1.5rem; 
        }
    }

    .footer__contacts {
        margin-bottom: $pad;
    }

    &__top {
        height: $header-height;
    }
}
