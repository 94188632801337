.cards {
    div[class^="col"] {
        margin-top: $pad*2;
        
        &:nth-child(2) {
            @media($breakpoint-mobile-up) {
                margin-top: $pad*6;
            }
        }
    }
    
    // }
}

.card {
    @extend .shadow;
    background: color(offwhite);
    padding: 2*$pad;
    margin-top: 2*$pad;

    > *:not(:first-child) {
        margin-top: $pad;
    }

    &__content {
        text-align: justify; 
    }
}