.iphone {
    max-width: 430px;
    // max-height: 700px; // It breaks somewhere between mobile and tablet if I include this
    position: relative;


    // .iphone__image
    &__image {
      
    }

    // .iphone__content
    &__content {
        position: absolute;
        top: 0;
        overflow-y: scroll;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 87%;
        top: 13%;
        bottom: 7%;

        &::-webkit-scrollbar {
          display: none;
        }

        &-image {
          max-height: none;
          position: absolute;
          top: 0;
          transition: all 5s;
        }
    }

    &--animating {
      .iphone__content-image {
        top: -100%;
      }
    }
}