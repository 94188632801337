$colors: (
    orange: #f89743,
    green: #5ba63f,
    grey: #404040,
    white: #ffffff,
    offwhite: #f1f1f1,
    offblack: #1d1d1d,
    black: #000000,
);

@function color($key) {
    @return map-get($colors, $key)
}

.color-highlight {
    color: color(orange);
}

.color-text {
    color: color(offblack);
}

@each $color, $hex in $colors {
    .color-#{$color} {
        color: $hex !important;
    }

    .bg-#{$color} {
        background-color: $hex;
    }

    .section--highlight-#{$color} {
        .color-highlight {
            color: $hex;
        }

        .btn {
            background: $hex;
            &:hover {
                color: $hex;
            }
        }
    }

    .btn--color-#{$color} {
        background: $hex !important;
        &:hover {
            color: $hex !important;
        }
    }
}



html {
    @extend .color-text;
}


