.footer {
    padding: $pad 0 0;

    // .footer__address 
    &__address {
        margin-bottom: $gutter;
    }

    // .footer__copyright
    &__copyright {
        @extend .text-center;
        // @extend .bg-offblack;
        padding: 0 0 $pad;
        margin-top: $pad *.75 !important;
    }

    &__socials {
        @extend .flex-justify-end;
        // margin-bottom: $pad;
        position: relative;
    }

    // .footer__baseline 
    &__baseline {
        @extend .bg-offblack;
        padding: $pad 0 0;
    }
}