.packageCalculator {
    @extend .bg-offwhite;
    flex: 1;
    padding: $pad;
    
    &__result {
        // @extend .h1;
        @extend .flex-col;
        @extend .flex-center;
        background-color: rgba(color(black), .5);
        font-size: 5rem;
        padding: $pad;
    }
}