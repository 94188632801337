.video {
    max-width: 100%;
    width: 100%;

    &__inner {
        position: relative;
        @extend .flex-center;
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__overlay {
        @extend .flex-align-stretch;
        position: absolute;
        transition: all $transition-duration;
        width: 100%;
        z-index: 1;
    }

    &__divider {
        width: $pad/6;
        background: color(white);
        
        @media ($breakpoint-mobile-down) {
            display: none;
        }
    }
    
    &__description {
        @extend .text-right;
        @extend .flex-col;
        @extend .flex-justify-center;
        flex-grow: 1;
        flex-basis: 1px;
        padding: $pad;
        
        *:not(:last-child) {
            margin-bottom: $pad/2;
        }

        @media ($breakpoint-mobile-down) {
            display: none;
        }
    }
    
    &__playButton-container {
        @extend .flex-align-center;
        flex-grow: 1;
        flex-basis: 1px;

        @media ($breakpoint-mobile-down) {
            justify-content: center;
        }
    }

    &__playButton {
        @extend .flex-center;
        border-radius: 50%;
        background: color(orange);
        width: 200px;
        height: 200px;
        margin: $pad;
        opacity: .8;
        transition: all $transition-duration;

        &:hover {
            opacity: 1;
        }

        @media ($breakpoint-tablet-down) {
            width: 100px;
            height: 100px;

            .fa {
                // I can't think of a nice way to handle responsive icon sizes without updating them all to a class-based structure, which I don't really want to do right now
                font-size: 2rem !important;
            }
        }
    }

    video {
        display: block;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        transition: opacity $transition-duration;
    }

    &--playButtonOnly {
        .video__playButton-container {
            justify-content: center;
        }
    }

    &--playing {
        .video__overlay {
            filter: blur(5px);
            opacity: 0;
            pointer-events: none;
        }

        video {
            opacity: 1;
            pointer-events: initial;
        }
    }
}